import { Button, Col, Form, Input, Row, Select, Spin, theme } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toastService, uploadApiService } from '~/services';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UploadOutlined } from '@ant-design/icons';
import DefUpload from '~/components/DefUpload';
import { configEnv } from '~/@config';
import { global } from '~/common/utils/global';
import collectionService from '~/services/collection.service';
import { ApiException } from '~/@core/dto';

const { Option } = Select;
const { IMAGE_URL } = configEnv();

const DetailCollections: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [banner, setBanner] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const loadDetail = useCallback(async () => {
    // setIsLoading(true);
    // try {
    //   if (!id) {
    //     throw new ApiException("Empty id")
    //   }
    //   const data = await collectionService.detail({ id });

    //   form.setFieldsValue({
    //     ...data,
    //     banner: data.image
    //   })

    //   setBanner(data.image);

    // } catch (error) {

    // }
    // setIsLoading(false);
  }, [id])

  useEffect(() => {
    loadDetail();
  }, [loadDetail])

  const onFinish = async (values: any) => {
    // try {
    //   setIsLoading(true);
    //   const body = {
    //     ...values,
    //     image: banner,
    //     id,
    //   }

    //   await collectionService.create(body);
    //   toastService.success("Update success!");
    //   navigate("/notification")

    // } catch (error) {
    //   toastService.handleError(error);
    // }
    // setIsLoading(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <Spin tip="Loading..." spinning={isLoading}  >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >


          <Form.Item >
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>

          <Row gutter={[24, 2]}>

            <Col span={8}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input Title!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Sub Title"
                name="subTitle"
                rules={[{ required: true, message: 'Please input Sub Title!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Redirect Link"
                name="redirectLink"
              >
                <Input />
              </Form.Item>
            </Col>

          </Row>

          <Col span={24}>
            <Form.Item
              name="banner"
              label="Upload Image"
              getValueProps={(value) => ({ value })}
            >
              <DefUpload
                name="banner"
                value={banner}
                accept="image/*"
                onChange={(url) => setBanner(url)}
                requestUpload={async (file) => {
                  const formData = new FormData();
                  formData.append("file", file);
                  try {
                    const res = await uploadApiService.uploadFile(
                      IMAGE_URL,
                      formData
                    );
                    return res?.fullUrl || "";
                  } catch (err) {
                  }
                }}

                listType="picture">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </DefUpload>
            </Form.Item>
          </Col>

        </Form>
      </Spin>

    </div>
  );
};

export default DetailCollections;
