import { Spin, Row, Descriptions, Col, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import BaseView from "~/components/BaseView"
import { IDashboard } from "~/dto/IDashboard"
import plantHarvestService from "~/services/plant.harvest.service"


export const DashboardView = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [dashboard, setDashboard] = useState<IDashboard>(null);

  const columns = [
    {
      title: "Registration Date",
      dataIndex: "registrationDate",
      key: "registrationDate",
      render: (value: any,) => {
        return dayjs(value).format("DD/MM/YYYY")
      }
    },
    {
      title: "Total Users",
      dataIndex: "totalUsers",
      key: "totalUsers",
    },
  ];

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const rs = await plantHarvestService.dashboard({});
      setDashboard(rs);
    } catch (error) {
      console.log([error]);
    }
    setIsLoading(false)

  }, [])

  useEffect(() => {
    loadData();
  }, [])

  return (
    <BaseView>

      <Spin
        spinning={isLoading}
      >

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "50px", alignItems: "end" }}
        >

          <Col span={24}>

            <div
              style={{ paddingBottom: "50px", }}
            >

              <Descriptions
                column={4}
                bordered
                title="Plant Harvest Information"
                layout="vertical"
                size='default'
                style={{
                  width: "100%"
                }}
              >

                <Descriptions.Item label=" Total Users">
                  {dashboard?.totalUsers}
                </Descriptions.Item>
                <Descriptions.Item label="User Active Today">
                  {dashboard?.userActiveToday}
                </Descriptions.Item>


              </Descriptions>

            </div>

          </Col>

          <Col span={24}>


            <Table
              bordered={true}
              columns={columns}
              dataSource={dashboard?.data}
            />

          </Col>

        </Row>

      </Spin>

    </BaseView>
  )
}
