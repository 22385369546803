
import { useState, FC, useCallback, useRef, useEffect } from 'react';
import { Button, Divider, theme, Col, Row, Spin, Input, Typography, Select, Avatar } from 'antd';
import { SaveOutlined, ScanOutlined, } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import { useNavigate } from 'react-router-dom';
import { configEnv } from '~/@config';
import { toastService } from '~/services';
import collectionService from '~/services/collection.service';

const { Title } = Typography;
const { Option } = Select;
type IProps = {
}

const { CHAINS, } = configEnv();

const id = "fe27de72-430f-4182-bf9b-a347d2e417f9";

const PopupTwitterView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [content, setContent] = useState("");
  const [twitterId, setTwitterId] = useState("");

  const loadData = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const rs = await collectionService.detailTw({
        id
      })
      setTwitterId(rs.twitterId);
      setContent(rs.content);
      setConfirmLoading(false);
    } catch (error) {
      console.log({ error });
      setConfirmLoading(false);
    }
  }, [])

  useEffect(() => {
    loadData();
  }, [])

  const saveTw = useCallback(async () => {

    setConfirmLoading(true);
    try {
      const rs = await collectionService.updateTw({
        id,
        twitterId,
        content,
      })
      loadData();
      toastService.success("Save Success!")
      setConfirmLoading(false);

    } catch (error) {
      console.log({ error });
      toastService.handleError(error)
      setConfirmLoading(false);
    }

  }, [content, loadData, twitterId])

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        <Col span={6}>
          <Button
            style={{ width: "180px" }}
            type="primary"
            loading={confirmLoading}
            onClick={saveTw}
          >
            Save
            <SaveOutlined />
          </Button>
        </Col>

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "50px", alignItems: "end" }}
        >

          <Col
            span={6}
            style={{ alignSelf: "start" }}
          >
            <Title level={5}>
              Twitter ID
            </Title>
            <Input
              placeholder=' Twitter ID'
              value={twitterId}
              onChange={e => { setTwitterId(e.target.value) }}
            />
          </Col>

          <Col span={18}>
            <Title level={5}>
              Content
            </Title>
            <Input.TextArea
              style={{ height: 300 }}
              placeholder='Enter Content'
              value={content}
              onChange={e => { setContent(e.target.value) }}
            />
          </Col>



        </Row>

      </Spin>

    </BaseView>
  )
}

export default PopupTwitterView;
