import { Spin, Row, Button, Col, Form, Input, InputNumber } from "antd";
import form from "antd/es/form";
import { ColumnsType } from "antd/es/table";
import { useForm } from "antd/lib/form/Form";
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageResponse } from "~/@ui/GridControl/models";
import BaseView from "~/components/BaseView";
import { toastService } from "~/services";
import mintrexPointService from "~/services/mintrex.point.service";

export const CreateMintString = () => {

  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {

    console.log({ values });

    setIsLoading(true);
    try {

      await mintrexPointService.create(values);
      toastService.success("Create Success");
      navigate("/mintrex/mint-string")

    } catch (error) {
      toastService.error(error)
    }
    setIsLoading(false);
  };

  const renderBody = useCallback(() => {

    return (
      <Fragment>

        <Row gutter={[24, 2]}>
          <Col span={8}>
            <Form.Item
              label="Qty Mint"
              name="qtyMint"
              rules={[{
                required: true,
                message: 'The input is not valid!'
              }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Bonus Point"
              name="bonusPoint"
              rules={[{
                required: true,
                message: 'The input is not valid!'
              }]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Desciption"
              name="desc"
              rules={[{
                required: true,
                message: 'The input is not valid!'
              }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

        </Row>
      </Fragment>
    )
  }, [])

  return (
    <BaseView>

      <Spin
        spinning={isLoading}
      >

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >

          <Form.Item >
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>

          {renderBody()}
        </Form>

      </Spin>

    </BaseView>
  )
}
