import React, { useState, useEffect } from "react";
import { Upload, Button, UploadProps } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface"
import { UploadOutlined } from "@ant-design/icons";




interface IDefUploadProps extends Omit<UploadProps, "onChange" | "customRequest"> {
  name: string;
  value: string;
  requestUpload: (file) => Promise<string>;
  onChange?: (value: string) => void;
}

const DefUpload = React.forwardRef<any, IDefUploadProps>((props, ref) => {
  const { onChange, value, name, requestUpload } = props;
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (!value) {
      setFileList([]);
    } else {
      setFileList([
        {
          uid: -1,
          name,
          url: value
        }
      ]);
    }

  }, [value, name]);

  const customRequest = async (options: UploadRequestOption) => {
    const { onSuccess, onError, file, onProgress } = options;
    try {
      onProgress({ percent: 0 });
      const url = await requestUpload(file);
      onProgress({ percent: 100 });
      onSuccess({ value: url });
      if (onChange) {
        onChange(url);
      }
    } catch (error) {
      onError(error);
    }
  };

  const onRemove = () => {
    onChange("");
  };

  const onUploadChange = (info) => {
    console.log("onUploadChange: ", info);
    setFileList([...info.fileList]);
  };

  return (
    <Upload
      {...props}
      customRequest={customRequest}
      onRemove={onRemove}
      ref={ref}
      fileList={fileList}
      onChange={onUploadChange}
      listType="picture"
    >
      <Button icon={<UploadOutlined />}>Click to upload</Button>
    </Upload>
  );
});

export default DefUpload;
