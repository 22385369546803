import { IEnvConfig } from '..';
import { CHAIN_CODE } from '../chain-code';

const config: IEnvConfig = {
  name: "QA",
  IMAGE_URL: "",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://qa.api.nftfeed.guru".trim(),
    },
    MINTREX: {
      baseUrl: "https://api.mint-rex.dogeden.app".trim(),
    },
    PLANT_HARTVEST: {
      baseUrl: "https://qa.api.funny-farm.xyz",
    }
  },
  CHAINS: [
  ]
}

export default config;
