import { IDashboard } from "~/dto/IDashboard";
import { plantHarvestApiService } from "./@common";

const ENDPOINT = {

  POST_NEWS: "/api/admin/post-news".trim(),

  DASHBOARD: "/api/admin/dashboard".trim(),

}

export class PLantHarvestService {

  async postNews(body: { description: string, imageTitle: string }) {
    return plantHarvestApiService.post(ENDPOINT.POST_NEWS, body);
  }

  async dashboard(body: {}) {
    return plantHarvestApiService.get<IDashboard>(ENDPOINT.DASHBOARD, body);
  }

}

export default new PLantHarvestService();
