import { Modal, Divider, Row, Col, Switch, Input, Tag, Button } from "antd"
import { CollectionsDTO } from "~/dto"
import Title from 'antd/es/typography/Title';
import { useState } from "react";

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const ModalMintConfigs = (props: {
  name: string,
  isOpen: boolean,
  close: () => void,
  itemCol: CollectionsDTO
  isLoading: boolean,
  handleOkModal: () => void,
  listTxh: string[],
  setListTxh: (value: string[]) => void
}) => {
  const {
    name,
    isOpen,
    isLoading,
    close,
    itemCol,
    handleOkModal,
    listTxh,
    setListTxh,
  } = props;

  const [txhValue, setTxhValue] = useState(null);

  return (
    <Modal
      width={"60%"}
      title={`Edit MINT CONFIG : [${name}]`}
      okType="danger"
      okText="Update"
      open={isOpen}
      onOk={() => {
        handleOkModal();
        setTxhValue(null);
      }}
      confirmLoading={isLoading}
      onCancel={close}
    >

      <Divider />
      {itemCol &&
        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "20px", alignItems: "end" }}
        >

          <Col span={24}>
            <div
              style={{
                display: "flex",
                margin: "auto",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "end"
              }}
            >
              <div>
                <Title level={5}>{"MINT CONFIG"}</Title>
              </div>
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setListTxh([]);
                  setTxhValue(null);
                }}
              >
                Clear All
              </Button>
            </div>

            <div
              style={{
                marginTop: 30,
                display: "flex",
                margin: "auto",
              }}
            >
              <Input
                value={txhValue}
                onChange={(e) => {
                  setTxhValue(e.target.value.trim())
                }}
              />
              <Button
                style={{ marginLeft: 10 }}
                onClick={() => {
                  if (txhValue) {
                    setListTxh([...listTxh, txhValue]);
                    setTxhValue(null);
                  }
                }}
              >
                Add Txh
              </Button>
            </div>

          </Col>

          <div style={{ marginTop: 20 }}>
            {listTxh.map((item, idx) => {
              return (
                <Tag key={idx} color={getRandomColor()} style={{ margin: 5 }}>{item}</Tag>
              )
            })}
          </div>

        </Row>
      }

    </Modal>
  )
}
