
import { rootApiService } from "./@common";
import { PageRequest, PageResponse } from "~/@ui/GridControl/models";
import { CollectionsDTO, } from "~/dto";
import { IPointHub } from "~/dto/IPointHub";
import { TwitterPopupDTO } from "~/dto/twitter.popup.dto";

const Enpoint = {
  list: "/api/nft-collections/list".trim(),
  edit: "/api/admin/edit-collection".trim(),

  detailTw: "/api/news/detail".trim(),
  updateTw: "/api/news/update".trim(),

  getPointHub: "/api/manager/loyalty-point-hub/get-config-by-collection".trim(),
  editPointHub: "/api/manager/loyalty-point-hub/update-config".trim(),
}

export class CollectionsService {

  async list(params: PageRequest & {
    "filterStatus"?: any,
    name?: string,
    address?: string,
  }) {
    return rootApiService.get<{ data: CollectionsDTO[], total: number }>(Enpoint.list, params);
  }

  async edit(params: {
    address: string;
    systemTag: number;
    chainId: number;
    isVerifyTwitter: boolean
    status: number,
    isSpecialMission: number,
    galxeHref: string,
    isHidden: boolean,
  }) {
    return rootApiService.post<CollectionsDTO>(Enpoint.edit, params);
  }
  // async create(params: CollectionsDTO) {
  //   return rootApiService.post(Enpoint.create, params);
  // }

  async detailTw(params: {
    id: string,
  }) {
    return rootApiService.get<TwitterPopupDTO>(Enpoint.detailTw, params);
  }

  async updateTw(params: {
    id: string,
    twitterId: string,
    content: string
  }) {
    return rootApiService.post<TwitterPopupDTO>(Enpoint.updateTw, params);
  }

  async getPointHub(params: {
    chainId: number,
    address: string,
  }) {
    return rootApiService.get<IPointHub>(Enpoint.getPointHub, params);
  }

  async editPointHub(params: IPointHub) {
    return rootApiService.post(Enpoint.editPointHub, params);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CollectionsService();
