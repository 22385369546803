
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Tag, Image, Avatar, Spin, Input, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import TableControl from '~/@ui/TableControl';
import { toastService } from '~/services';
import { DeleteOutlined, EditOutlined, PlusOutlined, SendOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { CollectionsDTO, } from '~/dto';
import collectionService from '~/services/collection.service';
import { PageResponse } from '~/@ui/GridControl/models';
import { EStatus, ESystemTag } from '~/common/enums';
import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import helper from '~/common/helper/helper';
import Title from 'antd/es/typography/Title';
import { ECollectionType } from '~/common/enums/ECollectionType';
import { PointHubModal } from './PointHubModal';


const checkURLType = (url: string) => {
  if (!url) {
    return "Other";
  }
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const extension = url.split('.').pop().toLowerCase();
  if (videoExtensions.includes(extension)) {
    return 'Video';
  } else if (imageExtensions.includes(extension)) {
    return 'Image';
  } else {
    return 'Other';
  }
}

type IProps = {
}

export interface IPointHubItem {
  nftFreeMintPointRate: number,
  nftMintPointRate: number,
  completedQuestPointRate: number,
}

const CollectionsView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const videoRef = useRef(null);

  const [openPointHub, setOpenPointHub] = useState(false);

  const [openDelModal, setOpenDelModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refesh, setRefesh] = useState(false);

  const [name, setName] = useState("");
  const [itemCol, setItemCol] = useState<CollectionsDTO>(null);
  const [total, setTotal] = useState(0);
  const [keySearch, setKeySearch] = useState({
    name: "",
    address: "",
  });

  const columns: ColumnsType<CollectionsDTO> = [
    {
      title: "Hided",
      dataIndex: "isHidden",
      key: "isHidden",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return value && <Tag color={"red"}>
          <span>Hided</span>
        </Tag>
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return <Tag color={value === EStatus.ACTIVE ? "green" : "red"}>
          <span>{value === EStatus.ACTIVE ? "Active" : "InActive"}</span>
        </Tag>
      }
    },
    {
      title: "Special Mint Missions",
      dataIndex: "isSpecialMission",
      key: "isSpecialMission",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return <Tag color={value === EStatus.ACTIVE ? "green" : "red"}>
          <span>{value === EStatus.ACTIVE ? "Active" : "InActive"}</span>
        </Tag>
      }
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (value: any, record: CollectionsDTO, index: number) => {
        const isEnded = new Date(record.endTime).getTime() < new Date().getTime();
        return <Tag color={"cyan"}>
          <span>{isEnded ? "End" : "Live"}</span>
        </Tag>
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: any, record: CollectionsDTO, index: number) => {
        let nameType = "";
        let color = "geekblue";

        switch (record.systemTag) {
          case ESystemTag.Popular:
            nameType = "POPULAR";
            color = "orange";
            break;
          case ESystemTag.SPECIAL:
            nameType = "SPECIAL NFT";
            color = "orange";
            break;
          default:
            if (record.price > 0) {
              nameType = "ON SALE"
              color = "green";
            } else {
              nameType = "FREE";
            }
            break;
        }

        return <Tag color={color}>
          <span>{nameType}</span>
        </Tag>
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return ECollectionType.CRAWL === value && <Tag color={"red"}>
          <span>NFT Aggregator</span>
        </Tag>
      }
    },
    {
      title: "Verfiy Twitter",
      dataIndex: "isVerifyTwitter",
      key: "isVerifyTwitter",
      width: 80,
      render: (value: any, record: CollectionsDTO, index: number) => {
        return value ? <Tag color={"blue"}>
          <span>Verfied</span>
        </Tag> : null
      }
    },
    {
      title: "Galxe",
      dataIndex: "galxeHref",
      key: "galxeHref",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return value && <Tag
          color={"default"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(value)
          }}
        >
          <span>Galxe</span>
        </Tag>
      }
    },
    {
      title: 'Name Collection',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: any, index: number) => {
        return (
          <Tooltip title={value}>
            <span>{helper.pipeLongTextUi(value, 30, 0)}</span>
          </Tooltip>
        )
      }
    },
    // {
    //   title: 'Address',
    //   dataIndex: 'address',
    //   key: 'address',
    //   ellipsis: true,
    //   render: (value: string, record: any, index: number) => {
    //     return <span>{helper.pipeLongTextUi(value, 4, 10)}</span>
    //   }
    // },
    {
      title: 'ChainId',
      dataIndex: 'chainId',
      key: 'chainId',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <div>
          <Avatar src={EVM_CHAIN_LIST[value].logo} size={"small"} />
          {` `} <span>{EVM_CHAIN_LIST[value].chainName}</span>
        </div>
      }
    },
    {
      title: 'Qty Minted',
      dataIndex: 'qtyMinted',
      key: 'qtyMinted',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{helper.formatPrice(value.toString())}</span>
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        if (checkURLType(value || "") === "Video") {
          return (
            <div
              style={{ height: 30, width: 30 }}
            >
              <video
                loop
                ref={videoRef}
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "inherit",
                  objectFit: "cover"
                }}
                //@ts-ignore
                autoPlay={true}
                //@ts-ignore
                playsinline="playsinline"
                //@ts-ignore
                muted="muted"
                src={value}
              >
              </video>
            </div>
          )
        }

        return <Avatar src={value} alt="banner" style={{ width: 30, height: 30 }} />
      }
    },
    {
      title: "Project",
      dataIndex: "address",
      key: "address",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return <Tag
          color={"default"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(`https://mint.nftfeed.guru/nft-collection/${record.chainId}/${record.address}`)
          }}
        >
          <span>Website</span>
        </Tag>
      }
    },
    {
      key: "action",
      title: "Action",
      ellipsis: true,
      fixed: 'right',
      render: (value: any, record: CollectionsDTO, index: number) => {
        return (
          <span>

            <Tooltip title="Action">
              <Button
                size='small'
                type="primary"
                icon={<EditOutlined />}
                title={"deactive"}
                onClick={openModal.bind(null, record)}
              >
                Action
              </Button>
            </Tooltip>

            <Tooltip title="Action">
              <Button
                size='small'
                type="primary"
                icon={<EditOutlined />}
                title={"deactive"}
                onClick={() => {
                  setOpenPointHub(true);
                  setName(record.name);
                  setItemCol(record);
                }}
              >
                Pointhub
              </Button>
            </Tooltip>

          </span>
        )
      }
    }

  ];

  const onChange = useCallback((key: string, val: string) => {
    setKeySearch({
      ...keySearch,
      [key]: val
    })
  }, [keySearch])

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    const rs = await collectionService.list({
      pageIndex, pageSize,
      ...(keySearch.address && { address: keySearch.address.trim() }),
      ...(keySearch.name && { name: keySearch.name.trim() }),
    })
    setRefesh(false);

    setTotal(rs.total);
    return {
      data: rs.data,
      total: rs.total
    } as PageResponse;
  }, [refesh])

  const handleCancelModal = () => {
    setOpenDelModal(false);
  };

  const openModal = useCallback((item: CollectionsDTO) => {
    setOpenDelModal(true);
    setName(item.name);
    setItemCol(item);
  }, []);

  const handleOkModal = async () => {
    setConfirmLoading(true);
    try {

      console.log({ itemCol });

      const rs = await collectionService.edit({
        address: itemCol.address,
        systemTag: itemCol.systemTag,
        chainId: itemCol.chainId,
        isVerifyTwitter: itemCol.isVerifyTwitter,
        status: itemCol.status,
        isSpecialMission: itemCol.isSpecialMission,
        galxeHref: itemCol.galxeHref,
        isHidden: itemCol.isHidden,
      });

      setOpenDelModal(false);
      setName("");
      setItemCol(null);
      toastService.success("Update success!");
      setConfirmLoading(false);
      setRefesh(!refesh)
    } catch (error) {
      toastService.error(error);
      setConfirmLoading(false);
    }
  }

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        <Row
          gutter={[24, 2]}
          style={{
            paddingBottom: 20
          }}
        >

          <Col span={8}>
            <Title level={5}>Name</Title>
            <Input
              placeholder='Name Collection'
              value={keySearch["name"]}
              onChange={(e) => onChange("name", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Address</Title>
            <Input
              placeholder='Address Collection'
              value={keySearch["address"]}
              onChange={(e) => onChange("address", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Search</Title>
            <Row>
              <Button onClick={() => { setRefesh(!refesh); }}>
                Search
              </Button>
              <Button onClick={() => {
                setKeySearch({
                  name: "",
                  address: "",
                })
                setRefesh(!refesh);
              }}>
                Reset
              </Button>
            </Row>
          </Col>

        </Row>

        <Title level={5}>Total collection: {total}</Title>

        <TableControl
          columns={columns}
          defaultPageSize={50}
          loadData={loadData}
        />

        <Modal
          title={`Edit project : [${name}]`}
          okType="danger"
          okText="SET"
          open={openDelModal}
          onOk={handleOkModal}
          confirmLoading={confirmLoading}
          onCancel={handleCancelModal}
        >

          <Divider />
          {itemCol &&
            <Row
              gutter={[24, 2]}
              style={{ paddingBottom: "20px", alignItems: "end" }}
            >

              <Col span={12}>
                <Title level={5}>{"Popular"}</Title>
                <Switch
                  checked={itemCol.systemTag === ESystemTag.Popular}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      systemTag: e ? ESystemTag.Popular : null,
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Special NFT"}</Title>
                <Switch
                  checked={itemCol.systemTag === ESystemTag.SPECIAL}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      systemTag: e ? ESystemTag.SPECIAL : null,
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Verify Twitter"}</Title>
                <Switch
                  checked={itemCol.isVerifyTwitter}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      isVerifyTwitter: e
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Status"}</Title>
                <Switch
                  checked={itemCol.status === EStatus.ACTIVE}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      status: e ? 1 : 0
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Special Mission"}</Title>
                <Switch
                  checked={itemCol.isSpecialMission === EStatus.ACTIVE}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      isSpecialMission: e ? 1 : 0
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Hidden"}</Title>
                <Switch
                  checked={itemCol.isHidden}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      isHidden: e
                    })
                  }}
                />
              </Col>

              <Col span={24}>
                <Title level={5}>{"Galxe"}</Title>
                <Input
                  value={itemCol.galxeHref}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      galxeHref: e.target.value
                    })
                  }}
                />
              </Col>

            </Row>
          }

        </Modal>

        <PointHubModal
          name={name}
          isOpen={openPointHub}
          onClose={() => { setOpenPointHub(false) }}
          itemCol={itemCol}
        />

      </Spin>

    </BaseView>
  )
}

export default CollectionsView;
