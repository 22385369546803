import { FC, Suspense, useEffect } from 'react';
import { ConfigProvider, Spin, theme } from "antd";

import moment, { locale } from 'moment';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './views/AppRouter';
import ActivityIndicator from './components/ActivityIndicator';
import { useThemeStore } from './stores/themeStore';
import { useAuthStore } from './stores/authStore';
import { IntlProvider } from 'react-intl';
import { localeConfig, LocaleFormatter } from './locales';
import { LOCALE_RESOURCE } from './locales/locale.resource';


interface IProps {

}
const App: FC<IProps> = (props: IProps) => {

  const { antdValue, momentValue } = LOCALE_RESOURCE["en"];
  const { changeTheme } = useThemeStore();
  const { authenticate, isFirstLoading } = useAuthStore();
  useEffect(() => {
    moment.locale(momentValue);
    authenticate();
  }, [authenticate, changeTheme, momentValue])

  const { themConfig } = useThemeStore();
  return (
    <ConfigProvider
      theme={{
        ...themConfig
      }}
      locale={antdValue}>
      <IntlProvider locale={'en'} messages={localeConfig["en_US"]}>

        <Suspense fallback={null}>
          <Spin
            spinning={isFirstLoading}
            className="app-loading-wrapper"
            tip={<LocaleFormatter id="gloabal.tips.loading" />}
          ></Spin>
          {isFirstLoading ? <ActivityIndicator /> : <AppRouter />}
        </Suspense>

      </IntlProvider>

    </ConfigProvider>
  )
}
export default App;
