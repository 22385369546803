
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Spin, Input, Typography, Select, Avatar } from 'antd';
import { ScanOutlined, } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import { useNavigate } from 'react-router-dom';
import { PageResponse } from '~/@ui/GridControl/models';
import mintRexApiService from '~/services/mintrex.collection.service';
import { configEnv } from '~/@config';
import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import { toastService } from '~/services';

const { Title } = Typography;
const { Option } = Select;
type IProps = {
}

const { CHAINS, } = configEnv();

const CrawView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [chainId, setChainId] = useState("");

  const crawNft = useCallback(async () => {

    setConfirmLoading(true);
    console.log(address, chainId);
    try {
      const rs = await mintRexApiService.crawlNft({ address, chainId: parseInt(chainId) })

      if (rs.address) {
        toastService.success("Crawl Success!")
        setConfirmLoading(false);
      }

    } catch (error) {
      console.log({ error });
      toastService.handleError(error)
      setConfirmLoading(false);
    }

  }, [address, chainId])

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "50px", alignItems: "end" }}
        >

          <Col
            span={8}
          >
            <Title level={5}>
              Chain
            </Title>
            <Select
              style={{ width: "100%" }}
              placeholder="Chain Id"
              allowClear
              onChange={(e) => {
                setChainId(e)
              }}
            >
              {CHAINS.map((item, idx) => {
                return <Option value={item.chainId} key={idx}>
                  <Avatar src={item.logo} size={"small"} />
                  {` `}{item.chainName}({item.chainId})
                </Option>
              })}
            </Select>
          </Col>

          <Col span={8}>
            <Title level={5}>
              Enter address crawl
            </Title>
            <Input
              placeholder='Enter address crawl'
              value={address}
              onChange={e => { setAddress(e.target.value) }}
            />
          </Col>

          <Col span={8}>
            <Button
              style={{ width: "180px" }}
              type="primary"
              loading={confirmLoading}
              onClick={crawNft}
            >
              Crawl NFT
              <ScanOutlined />
            </Button>
          </Col>

        </Row>

      </Spin>

    </BaseView>
  )
}

export default CrawView;
