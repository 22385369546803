
import { PageRequest, } from "~/@ui/GridControl/models";
import { mintRexApiService } from "./@common";
import { IPointMintRex } from "~/dto/point.mintrex.dto";

const Enpoint = {
  list: "/api/admin/points/list-system-mint-rule".trim(),
  create: "/api/admin/points/create-system-mint-rule".trim(),
  edit: "/api/admin/points/update-system-mint-rule".trim(),

}

export class MintRexPointService {

  async list(params: PageRequest) {
    return mintRexApiService.get<{ data: IPointMintRex[], total: number }>(Enpoint.list, params);
  }

  async create(params: {
    qtyMint: number,
    bonusPoint: number,
    desc: string
  }) {
    return mintRexApiService.post(Enpoint.create, params);
  }

  async edit(params: {
    qtyMint: number,
    bonusPoint: number,
    desc: string,
    id: string,
  }) {
    return mintRexApiService.post(Enpoint.edit, params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MintRexPointService();
