
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Tag, Image, Avatar, Spin, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import TableControl from '~/@ui/TableControl';
import { mintRexApiService, toastService } from '~/services';
import { DeleteOutlined, EditOutlined, PlusOutlined, SendOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { CollectionsDTO, } from '~/dto';
import mintrexCollectionService from '~/services/mintrex.collection.service';
import { PageResponse } from '~/@ui/GridControl/models';
import { EStatus, ESystemTag } from '~/common/enums';
import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import helper from '~/common/helper/helper';
import Title from 'antd/es/typography/Title';
import { Input } from 'antd/lib';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ModalMintConfigs } from './ModalMintConfigs';

const checkURLType = (url: string) => {
  if (!url) {
    return "Other";
  }
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const extension = url.split('.').pop().toLowerCase();
  if (videoExtensions.includes(extension)) {
    return 'Video';
  } else if (imageExtensions.includes(extension)) {
    return 'Image';
  } else {
    return 'Other';
  }
}

type IProps = {
}

const CollectionsViewMintRex: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const [openMintConfig, setOpenMintConfig] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refesh, setRefesh] = useState(true);

  const [name, setName] = useState("");

  const [listTxh, setListTxh] = useState([]);

  const [itemCol, setItemCol] = useState<CollectionsDTO>(null);
  const [total, setTotal] = useState(0);
  const [keySearch, setKeySearch] = useState({
    name: "",
    address: "",
  });

  const columns: ColumnsType<CollectionsDTO> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "type",
      render: (value: EStatus, record: CollectionsDTO, index: number) => {
        const label = value === EStatus.ACTIVE ? "ACTIVE" : "INACTIVE";
        return (
          <Tag color={value ? "success" : "default"}>
            <span>{label}</span>
          </Tag>
        )
      }
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: any, record: CollectionsDTO, index: number) => {
        let nameType = "";
        let color = "";

        if (record.systemTag === ESystemTag.Popular) {
          nameType = "OUTSTANDING";
          color = "orange";
        } else {
          if (record.systemTag === ESystemTag.HOME) {
            nameType = "TOP";
            color = "volcano";
          } else {
            nameType = "NORMAL";
            color = "cyan";
          }
        }

        return <Tag color={color}>
          <span>{nameType}</span>
        </Tag>
      }
    },
    {
      title: "Offers",
      dataIndex: "isOffer",
      key: "isOffer",
      render: (value: EStatus, record: CollectionsDTO, index: number) => {
        const label = value === EStatus.ACTIVE ? "ACTIVE" : "INACTIVE";
        return (
          <Tag color={value ? "success" : "default"}>
            <span>{label}</span>
          </Tag>
        )
      }
    },
    {
      title: 'Name Collection',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: any, index: number) => {
        return (
          <Tooltip title={value}>
            <span>{helper.pipeLongTextUi(value, 30, 0)}</span>
          </Tooltip>
        )
      }
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{helper.pipeLongTextUi(value, 4, 10)}</span>
      }
    },
    {
      title: 'ChainId',
      dataIndex: 'chainId',
      key: 'chainId',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{EVM_CHAIN_LIST[value].chainName}</span>
      }
    },
    {
      title: 'Qty Minted',
      dataIndex: 'qtyMinted',
      key: 'qtyMinted',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{helper.formatPrice(value.toString())}</span>
      }
    },
    {
      title: 'Image',
      dataIndex: 'logo',
      key: 'logo',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        if (checkURLType(value || "") === "Video") {
          return (
            <div
              style={{ height: 30, width: 30 }}
            >
              <video
                loop
                ref={videoRef}
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "inherit",
                  objectFit: "cover"
                }}
                //@ts-ignore
                autoPlay={true}
                //@ts-ignore
                playsinline="playsinline"
                //@ts-ignore
                muted="muted"
                src={value}
              >
              </video>
            </div>
          )
        }

        return <Avatar src={value} alt="banner" style={{ width: 30, height: 30 }} />
      }
    },
    {
      title: "Project",
      dataIndex: "address",
      key: "address",
      render: (value: any, record: CollectionsDTO, index: number) => {
        return <Tag
          color={"default"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(`https://mint-rex.fun/${record.chainId}/${record.address}`)
          }}
        >
          <span>Website</span>
        </Tag>
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      render: (value: string, record: CollectionsDTO, index: number) => {
        return (
          <Tooltip title={value}>
            <span>
              {record.description
                ? helper.pipeLongTextUi(value, 10, 0)
                : (record.descriptionCustom ? helper.pipeLongTextUi(record.descriptionCustom, 10, 0) : "")
              }
            </span>
          </Tooltip>
        )
      }
    },
    {
      key: "action",
      title: "Action",
      ellipsis: true,
      fixed: 'right',
      render: (value: any, record: CollectionsDTO, index: number) => {
        return (
          <span>

            <Tooltip title="Action">
              <Button
                onClick={openModal.bind(null, record,)}
                type="primary" danger={!Boolean(value)}
                size='small'
              >
                Action
              </Button>
            </Tooltip>

            <Tooltip title="Mint Configs">
              <Button
                onClick={openModalMintConfig.bind(null, record,)}
                type="primary" danger={!Boolean(value)}
                size='small'
              >
                Update Mint Configs
              </Button>
            </Tooltip>

          </span>
        )
      }
    }

  ];

  const onChange = useCallback((key: string, val: string) => {
    setKeySearch({
      ...keySearch,
      [key]: val
    })
  }, [keySearch])

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    const rs = await mintrexCollectionService.list({
      pageIndex, pageSize,
      ...(keySearch.address && { address: keySearch.address.trim() }),
      ...(keySearch.name && { name: keySearch.name.trim() }),
    })

    setTotal(rs.total)
    return {
      data: rs.data,
      total: rs.total
    } as PageResponse;
  }, [refesh])

  const handleCancelModal = () => {
    setOpenDelModal(false);
  };

  const openModal = useCallback((item: CollectionsDTO,) => {
    setOpenDelModal(true);
    setName(item.name);
    setItemCol(item);
  }, []);

  const openModalMintConfig = useCallback((item: CollectionsDTO,) => {
    setOpenMintConfig(true);
    setName(item.name);
    setItemCol(item);
  }, []);

  const handleOkModal = async () => {
    setConfirmLoading(true);
    try {

      const rs = await mintrexCollectionService.updateCollection({
        address: itemCol.address,
        systemTag: itemCol.systemTag,
        chainId: itemCol.chainId,
        status: itemCol.status,
        isOffer: itemCol.isOffer,
        descriptionCustom: itemCol.descriptionCustom,
        twitterId: itemCol.twitterId,
      });

      setName("");
      setItemCol(null);
      toastService.success("Update success!");
      setOpenDelModal(false);
      setConfirmLoading(false);
      setRefesh(!refesh)
    } catch (error) {
      toastService.error(error);
      setConfirmLoading(false);
    }
  }

  const saveMintConfig = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const body = {
        address: itemCol.address,
        chainId: itemCol.chainId,
        txHashs: listTxh,
      }
      console.log({ body });

      const rs = await mintrexCollectionService.updateMintConfig(body);

      setName("");
      setItemCol(null);
      setListTxh([]);
      toastService.success("Update Mint Config success!");
      setOpenMintConfig(false);
      setConfirmLoading(false);
      setRefesh(!refesh)
    } catch (error) {
      toastService.error(error);
      setConfirmLoading(false);
    }
  }, [itemCol?.address, itemCol?.chainId, listTxh,])

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        <Row
          gutter={[24, 2]}
          style={{
            paddingBottom: 20
          }}
        >

          <Col span={8}>
            <Title level={5}>Name</Title>
            <Input
              placeholder='Name Collection'
              value={keySearch["name"]}
              onChange={(e) => onChange("name", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Address</Title>
            <Input
              placeholder='Address Collection'
              value={keySearch["address"]}
              onChange={(e) => onChange("address", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Search</Title>
            <Row>
              <Button onClick={() => { setRefesh(!refesh) }}>
                Search
              </Button>
              <Button onClick={() => {
                setKeySearch({
                  name: "",
                  address: "",
                })
                setRefesh(!refesh);
              }}>
                Reset
              </Button>
            </Row>
          </Col>

        </Row>

        <Title level={5}>Total collection: {total}</Title>

        <TableControl
          columns={columns}
          defaultPageSize={20}
          loadData={loadData}
        />

        <Modal
          width={"60%"}
          title={`Edit project : [${name}]`}
          okType="danger"
          okText="SET"
          open={openDelModal}
          onOk={handleOkModal}
          confirmLoading={confirmLoading}
          onCancel={handleCancelModal}
        >

          <Divider />
          {itemCol &&
            <Row
              gutter={[24, 2]}
              style={{ paddingBottom: "20px", alignItems: "end" }}
            >

              <Col span={12}>
                <Title level={5}>{"Status"}</Title>
                <Switch
                  checked={itemCol.status === EStatus.ACTIVE}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      status: !e ? EStatus.INACTIVE : EStatus.ACTIVE,
                      systemTag: !e ? null : itemCol.systemTag,
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Outstanding"}</Title>
                <Switch
                  checked={itemCol.systemTag === ESystemTag.Popular}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      systemTag: e ? ESystemTag.Popular : null,
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Top"}</Title>
                <Switch
                  checked={itemCol.systemTag === ESystemTag.HOME}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      systemTag: e ? ESystemTag.HOME : null,
                    })
                  }}
                />
              </Col>

              <Col span={12}>
                <Title level={5}>{"Offer"}</Title>
                <Switch
                  checked={itemCol.isOffer === 1}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      isOffer: !e ? 0 : 1,
                    })
                  }}
                />
              </Col>

              <Col
                span={12}
              >
                <Title level={5}>
                  Twitter ID
                </Title>
                <Input
                  placeholder=' Twitter ID'
                  value={itemCol.twitterId}
                  onChange={e => {
                    setItemCol({
                      ...itemCol,
                      twitterId: e.target.value,
                    })
                  }}
                />
              </Col>

              <Col span={24}>
                <Title level={5}>{"Description"}</Title>
                <ReactQuill
                  style={{
                    width: "100%",
                    maxHeight: 700,
                    height: 700,
                    marginBottom: 80
                  }}

                  formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video', 'color'
                  ]}
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' },
                      { 'indent': '-1' }, { 'indent': '+1' }],
                      ['link', 'image', 'video'],
                      ['clean']
                    ],
                    clipboard: {
                      // toggle to add extra line breaks when pasting HTML:
                      matchVisual: false,
                    }
                  }}

                  theme="snow"
                  value={itemCol.descriptionCustom}
                  onChange={(value, delta, source, editor) => {
                    setItemCol({
                      ...itemCol,
                      descriptionCustom: value
                    })
                  }}
                />
              </Col>

            </Row>
          }

        </Modal>

        <ModalMintConfigs
          name={name}
          isOpen={openMintConfig}
          close={() => { setOpenMintConfig(false) }}
          itemCol={itemCol}
          isLoading={confirmLoading}
          handleOkModal={saveMintConfig}
          listTxh={listTxh}
          setListTxh={(value: string[]) => setListTxh(value)}
        />

      </Spin>

    </BaseView>
  )
}

export default CollectionsViewMintRex;
