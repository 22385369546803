import React, { FC } from 'react';
import { Spin, Alert } from 'antd';

const ActivityIndicator: FC = () => {
    return (
        <div style={{ width: "100%", height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin >
            </Spin>
        </div>

    );
};

export default ActivityIndicator;
