import { Modal, Divider, Row, Col, Switch, Input, InputNumber } from "antd"
import { ESystemTag } from "~/common/enums"
import { CollectionsDTO } from "~/dto"
import Title from 'antd/es/typography/Title';
import { IPointHubItem } from "..";
import { useCallback, useEffect, useState } from "react";
import collectionService from "~/services/collection.service";
import { toastService } from "~/services";

export const PointHubModal = (props: {
  name: string,
  isOpen: boolean,
  onClose: () => void,
  itemCol: CollectionsDTO
}) => {

  const { name, isOpen, onClose, itemCol, } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [pointHubItem, setPointHubItem] = useState<IPointHubItem>({
    nftFreeMintPointRate: 0,
    nftMintPointRate: 0,
    completedQuestPointRate: 0,
  });

  const getPointHub = useCallback(async () => {
    setIsLoading(true);
    try {
      if (itemCol) {
        const rs = await collectionService.getPointHub({
          chainId: itemCol.chainId,
          address: itemCol.address,
        })
        setPointHubItem(rs);
      }

    } catch (error) {
      console.log({ error });

      setPointHubItem({
        nftFreeMintPointRate: 0,
        nftMintPointRate: 0,
        completedQuestPointRate: 0,
      })
    }
    setIsLoading(false);
  }, [itemCol])

  useEffect(() => {
    getPointHub();
  }, [getPointHub])

  const handleOk = useCallback(async () => {
    setIsLoading(true)
    try {

      if (itemCol) {
        const rs = await collectionService.editPointHub({
          ...pointHubItem,
          chainId: itemCol.chainId,
          address: itemCol.address,
        })
        onClose();
        toastService.success("Update success!");
      }

    } catch (error) {
      console.log({ error });
    }
    setIsLoading(false)
  }, [itemCol, pointHubItem])

  return (
    <Modal
      title={`Edit POINT HUB : [${name}]`}
      okType="danger"
      okText="SET"
      open={isOpen}
      onOk={handleOk}
      confirmLoading={isLoading}
      onCancel={onClose}
    >

      <Divider />
      {itemCol &&
        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "20px", alignItems: "end" }}
        >

          {/* <Col span={12}>
            <Title level={5}>{"NFT Free Mint Point Rate"}</Title>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='Enter point'
              value={pointHubItem.nftFreeMintPointRate}
              onChange={e => {
                setPointHubItem({
                  ...pointHubItem,
                  nftFreeMintPointRate: e
                })
              }}
            />
          </Col> */}

          <Col span={12}>
            <Title level={5}>{"NFT Mint Point Rate"}</Title>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='Enter point'
              value={pointHubItem.nftMintPointRate}
              onChange={e => {
                setPointHubItem({
                  ...pointHubItem,
                  nftMintPointRate: e
                })
              }}
            />
          </Col>

          <Col span={12}>
            <Title level={5}>{"Completed Quest Point Rate"}</Title>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='Enter point'
              value={pointHubItem.completedQuestPointRate}
              onChange={e => {
                setPointHubItem({
                  ...pointHubItem,
                  completedQuestPointRate: e
                })
              }}
            />
          </Col>

        </Row>
      }

    </Modal>
  )

}
