import { IEnvConfig } from '..';
import { CHAIN_CODE } from '../chain-code';
import { EVM_CHAIN_LIST } from '../chain-list';

const config: IEnvConfig = {
  name: "PRODUCTION",
  IMAGE_URL: "",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://api.nftfeed.guru".trim(),
    },
    MINTREX: {
      baseUrl: "https://api.mint-rex.dogeden.app".trim(),
    },
    PLANT_HARTVEST: {
      // baseUrl: "https://api.funny-farm.xyz".trim(),
      baseUrl: "https://stg.api.plantharvest.fun",
    }
  },
  CHAINS: [
    EVM_CHAIN_LIST[CHAIN_CODE.ARBITRUM_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.BASE_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.OP_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.SCROLL_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.OPBNB],
    EVM_CHAIN_LIST[CHAIN_CODE.BSC_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.LINEA],
    EVM_CHAIN_LIST[CHAIN_CODE.ETH],
    EVM_CHAIN_LIST[CHAIN_CODE.POLYGON_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.MANTA],
    EVM_CHAIN_LIST[CHAIN_CODE.TAIKO],
    EVM_CHAIN_LIST[CHAIN_CODE.BLAST_MAINET],
    EVM_CHAIN_LIST[CHAIN_CODE.BLAST_TESTNET],
    EVM_CHAIN_LIST[CHAIN_CODE.VICTION_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.KROMA_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.MODE_MAINET],
    EVM_CHAIN_LIST[CHAIN_CODE.MINT_MAINET],
  ]
}

export default config;
