
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Tag, Image, Avatar, Spin, Input, DatePicker, Descriptions, Typography, } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { SaveOutlined, SearchOutlined, SendOutlined, UploadOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import helper from '~/common/helper/helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { optionalApiService, toastService, uploadApiService } from '~/services';
import plantHarvestService from '~/services/plant.harvest.service';
import DefUpload from '~/components/DefUpload';

const { Title } = Typography;

type IProps = {
}

export const NewOnTonView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const reactQuillRef = useRef(null);

  const [description, setDescription] = useState("");
  const [imageTitle, setImageTitle] = useState("");

  const postNews = useCallback(async () => {
    console.log({ description });
    setIsLoading(true);
    try {
      const rs = await plantHarvestService.postNews({ description, imageTitle });
      toastService.success("Post Sucess!")
      setDescription("");
      setImageTitle("");
    } catch (error) {
      console.log([error]);
    }
    setIsLoading(false)

  }, [description, imageTitle])

  const postNewsTest = useCallback(async () => {
    console.log({ description });
    setIsLoading(true);
    try {
      const rs = await optionalApiService.post(
        "https://qa.api.funny-farm.xyz/api/admin/post-news",
        { description, imageTitle }
      )
      toastService.success("Post TEST Sucess!")
      setDescription("");
      setImageTitle("");
    } catch (error) {
      console.log([error]);
    }
    setIsLoading(false)

  }, [description, imageTitle])

  return (
    <BaseView>

      <Spin
        spinning={false}
      >

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "50px", alignItems: "end" }}
        >

          <Col span={24}>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >

              <Button
                style={{ width: "210px" }}
                type="primary"
                onClick={postNewsTest}
                loading={isLoading}
                disabled={!description && !imageTitle}
              >
                [TEST] Post On Telegram
                <SaveOutlined />
              </Button>

              <Button
                style={{ width: "200px" }}
                type="primary"
                onClick={postNews}
                loading={isLoading}
                disabled={!description && !imageTitle}
              >
                Post On Telegram
                <SaveOutlined />
              </Button>

            </div>

          </Col>

          <Col span={24}>

            <Title level={5}>
              Image
            </Title>

            <DefUpload
              name="banner"
              value={imageTitle}
              accept="*"
              onChange={(url) => setImageTitle(url)}
              requestUpload={async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                try {
                  const res = await uploadApiService.uploadFile(
                    "https://cdn.nftfeed.guru/api/upload",
                    formData
                  );
                  return res?.fullUrl || "";
                } catch (err) {
                }
              }}

              listType="picture">
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </DefUpload>

          </Col>

          <Col span={24}>

            <Title level={5}>
              Description
            </Title>

            <ReactQuill
              ref={reactQuillRef}
              style={{
                width: "100%",
                maxHeight: 700,
                height: 700,
                marginBottom: 80
              }}

              modules={{
                toolbar: {
                  container: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link',],
                    ['clean']
                  ],
                },
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
                "code-block",
              ]}
              theme="snow"
              value={description}
              onChange={(value, delta, source, editor) => {
                setDescription(value)
              }}
            />
          </Col>


        </Row>

      </Spin>

    </BaseView>
  )
}
