export interface IEvmChainConfig {
  chainId: number,
  rpcUrls: string[],
  chainName: string,
  nativeCurrency: {
    name: string,
    symbol: string,
    decimals: number,
  },
  blockExplorerUrls: string[],
  iconUrls?: string[],
  logo: string
}

export const EVM_CHAIN_LIST: { [chainId: number]: IEvmChainConfig } = {
  1: {
    chainId: 1,
    rpcUrls: ["https://rpc.ankr.com/eth", "https://cloudflare-eth.com"],
    chainName: "ETH",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["https://etherscan.io"],
    logo: "https://dd.dexscreener.com/ds-data/chains/ethereum.png",
  },
  56: {
    chainId: 56,
    rpcUrls: ["https://bsc-dataseed1.ninicoin.io"],
    chainName: "BNB",
    nativeCurrency: {
      name: "BNB Chain",
      symbol: "BNB",
      decimals: 18,
    },
    blockExplorerUrls: ["https://bscscan.com/"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png",
  },

  97: {
    chainId: 97,
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [
      "https://data-seed-prebsc-2-s1.bnbchain.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
      "https://data-seed-prebsc-2-s1.binance.org:8545",
    ],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/bsc.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/bsc.png",
  },
  // Polygon
  137: {
    chainId: 137,
    rpcUrls: ["https://polygon-rpc.com"],
    chainName: "Polygon",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png",
  },
  80001: {
    chainId: 80001,
    chainName: "Polygon Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
    ],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/polygon.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/polygon.png",
  },

  8453: {
    chainId: 8453,
    chainName: "Base",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://mainnet.base.org",
      "https://developer-access-mainnet.base.org",
    ],
    blockExplorerUrls: ["https://basescan.org"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/base.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/base.png",
  },
  84531: {
    chainName: "Base Goerli Testnet",
    rpcUrls: ["https://goerli.base.org"],
    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18,
    },
    chainId: 84531,
    blockExplorerUrls: ["https://goerli.basescan.org"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/base.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/base.png",
  },
  10: {
    chainName: "Optimism",
    rpcUrls: ["https://mainnet.optimism.io/"],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    chainId: 10,
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png",
  },
  420: {
    chainName: "OP Goerli Testnet",
    rpcUrls: ["https://goerli.optimism.io/"],

    nativeCurrency: {
      name: "Goerli Ether",
      symbol: "ETH",
      decimals: 18,
    },
    chainId: 420,
    blockExplorerUrls: ["https://goerli-optimism.etherscan.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/optimism.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/optimism.png",
  },
  7000: {
    chainId: 7000,
    chainName: "ZetaChain",
    nativeCurrency: { name: "Zeta", symbol: "ZETA", decimals: 18 },
    rpcUrls: [
      "https://zetachain-mainnet-archive.allthatnode.com:8545",
      "https://zetachain-evm.blockpi.network/v1/rpc/public",
    ],
    blockExplorerUrls: ["https://explorer.zetachain.com/"],
    iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png"],
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png",
  },
  7001: {
    chainId: 7001,
    chainName: "ZetaChain Testnet",
    nativeCurrency: { name: "Zeta", symbol: "aZETA", decimals: 18 },
    rpcUrls: ["https://rpc.ankr.com/zetachain_evm_athens_testnet"],
    blockExplorerUrls: [
      "https://athens3.explorer.zetachain.com",
      "https://zetachain-athens-3.blockscout.com",
    ],
    iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png"],
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png",
  },

  280: {
    chainId: 280,
    chainName: "zkSync Era Testnet",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://testnet.era.zksync.dev"],
    blockExplorerUrls: ["https://goerli.explorer.zksync.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/zksync.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/zksync.png",
  },
  324: {
    chainId: 324,
    chainName: "zkSync Era",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://mainnet.era.zksync.io"],
    blockExplorerUrls: ["https://explorer.zksync.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/zksync.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/zksync.png",
  },
  42161: {
    chainId: 42161,
    chainName: "Arbitrum One",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: [
      "https://arbitrum.llamarpc.com",
      "https://arbitrum-one.publicnode.com",
      "https://arb1.arbitrum.io/rpc",
    ],
    blockExplorerUrls: [
      "https://arbiscan.io",
      "https://explorer.arbitrum.io",
      "https://arbitrum.dex.guru",
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/arbitrum.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/arbitrum.png",
  },
  421613: {
    chainId: 421613,
    chainName: "Arbitrum Goerli",
    nativeCurrency: {
      name: "Arbitrum Goerli Ether",
      symbol: "AGOR",
      decimals: 18,
    },
    rpcUrls: [
      "https://goerli-rollup.arbitrum.io/rpc",
      "https://arbitrum-goerli.publicnode.com",
    ],
    blockExplorerUrls: ["https://goerli.arbiscan.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/arbitrum.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/arbitrum.png",
  },
  534351: {
    chainId: 534351,
    chainName: "Scroll Sepolia Testnet",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: [
      "https://sepolia-rpc.scroll.io",
      "https://rpc.ankr.com/scroll_sepolia_testnet",
      "https://scroll-sepolia.chainstacklabs.com",
      "https://scroll-testnet-public.unifra.io",
    ],
    blockExplorerUrls: [
      "https://sepolia.scrollscan.dev",
      "https://sepolia-blockscout.scroll.io",
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/scroll.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/scroll.png",
  },
  534352: {
    chainId: 534352,
    chainName: "Scroll",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: [
      "https://scroll-mainnet.chainstacklabs.com",
      "https://rpc.scroll.io",
      "https://rpc-scroll.icecreamswap.com",
      "https://rpc.ankr.com/scroll",
    ],
    blockExplorerUrls: [
      "https://scrollscan.com",
      "https://blockscout.scroll.io",
    ],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/scroll.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/scroll.png",
  },
  43114: {
    chainId: 43114,
    chainName: "Avalanche",
    nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
    rpcUrls: [
      "https://avalanche.drpc.org",
      "https://endpoints.omniatech.io/v1/avax/mainnet/public",
    ],
    blockExplorerUrls: ["https://snowtrace.io"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/avalanche.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/avalanche.png",
  },
  7777777: {
    chainId: 7777777,
    chainName: "Zora",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://rpc.zora.energy"],
    blockExplorerUrls: ["https://zora.superscan.network/"],
    iconUrls: [
      "https://cdn.routescan.io/_next/image?url=https%3A%2F%2Fcms-cdn.avascan.com%2Fcms2%2Fzora.a97ea9115d55.png&w=32&q=75",
    ],
    logo: "https://cdn.routescan.io/_next/image?url=https%3A%2F%2Fcms-cdn.avascan.com%2Fcms2%2Fzora.a97ea9115d55.png&w=32&q=75",
  },
  204: {
    chainId: 204,
    chainName: "opBNB",
    nativeCurrency: {
      name: "BNB Chain",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://opbnb-mainnet-rpc.bnbchain.org"],
    blockExplorerUrls: ["https://opbnbscan.com"],
    iconUrls: ["https://s1.coincarp.com/logo/1/binance-coin.png?style=36"],
    logo: "https://s1.coincarp.com/logo/1/binance-coin.png?style=36",
  },
  59144: {
    chainId: 59144,
    chainName: "Linea",
    nativeCurrency: {
      name: "Linea Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.linea.build"],
    blockExplorerUrls: ["https://lineascan.build"],
    iconUrls: ["https://s1.coincarp.com/logo/1/linea.png?style=72"],
    logo: "https://s1.coincarp.com/logo/1/linea.png?style=72",
  },
  169: {
    chainId: 169,
    chainName: "Manta Pacific",
    nativeCurrency: {
      name: "Manta Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://1rpc.io/manta",
      "https://pacific-rpc.manta.network/http",
    ],
    blockExplorerUrls: ["https://pacific-explorer.manta.network/"],
    iconUrls: ["https://icons.llamao.fi/icons/chains/rsz_manta.jpg"],
    logo: "https://icons.llamao.fi/icons/chains/rsz_manta.jpg",
  },
  167008: {
    chainId: 167008,
    chainName: "Taiko Katla",
    nativeCurrency: {
      name: "Taiko Katla Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.katla.taiko.xyz"],
    blockExplorerUrls: ["https://explorer.katla.taiko.xyz/"],
    iconUrls: [
      "https://raw.githubusercontent.com/taikoxyz/taiko-mono/main/packages/branding/RGB%20SVG%20(For%20Digital%20Use)/Taiko%20Icon/taiko-icon-blk.svg",
    ],
    logo: "https://raw.githubusercontent.com/taikoxyz/taiko-mono/main/packages/branding/RGB%20SVG%20(For%20Digital%20Use)/Taiko%20Icon/taiko-icon-blk.svg",
  },
  81457: {
    chainId: 81457,
    chainName: "Blast",
    nativeCurrency: {
      name: "Blast Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc.ankr.com/blast",
      "https://blast.blockpi.network/v1/rpc/public",
    ],
    blockExplorerUrls: ["https://blastexplorer.io/"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/blast.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/blast.png",
  },
  168587773: {
    chainId: 168587773,
    chainName: "Blast Sepolia Testnet",
    nativeCurrency: {
      name: "Blast Sepolia Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://blast-sepolia.blockpi.network/v1/rpc/public",
      "https://sepolia.blast.io",
    ],
    blockExplorerUrls: ["https://testnet.blastscan.io/"],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/blast.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/blast.png",
  },
  88: {
    chainId: 88,
    chainName: "Viction",
    nativeCurrency: {
      name: "Viction",
      symbol: "VIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc.viction.xyz",
    ],
    blockExplorerUrls: ["https:/vicscan.xyz/"],
    iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/2570.png"],
    logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/2570.png",
  },
  255: {
    chainId: 255,
    chainName: "Kroma",
    nativeCurrency: {
      name: "Kroma",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://api.kroma.network",
      "https://1rpc.io/kroma",
    ],
    blockExplorerUrls: ["https://kromascan.com/"],
    iconUrls: ["https://kroma.network/_next/image?url=https%3A%2F%2Fcdn.kroma.network%2Fhub%2Fimages%2Fkroma-symbol.png&w=96&q=75"],
    logo: "https://kroma.network/_next/image?url=https%3A%2F%2Fcdn.kroma.network%2Fhub%2Fimages%2Fkroma-symbol.png&w=96&q=75",
  },
  0: {
    chainId: 0,
    chainName: "TON",
    nativeCurrency: {
      name: "TON",
      symbol: "TON",
      decimals: 18,
    },
    rpcUrls: [],
    blockExplorerUrls: [],
    iconUrls: ["https://dd.dexscreener.com/ds-data/chains/ton.png"],
    logo: "https://dd.dexscreener.com/ds-data/chains/ton.png",
  },
  34443: {
    chainId: 34443,
    chainName: "Mode",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://mainnet.mode.network",
      "wss://mode.drpc.org",
    ],
    blockExplorerUrls: ["https://explorer.mode.network/"],
    iconUrls: ["https://explorer.mode.network/assets/configs/network_logo.jpg"],
    logo: "https://explorer.mode.network/assets/configs/network_logo.jpg",
  },
  185: {
    chainId: 185,
    chainName: "Mint",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc.mintchain.io",
      "https://global.rpc.mintchain.io",
    ],
    blockExplorerUrls: ["https://explorer.mintchain.io/"],
    iconUrls: ["https://owlto.finance/icon/chain/Mint.png"],
    logo: "https://owlto.finance/icon/chain/Mint.png",
  },
};
