import { ThemeConfig, theme } from "antd";
import { useState, useEffect, useCallback, useMemo, createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyLocalStore } from "~/common/constants";
import { LoginReq, UserSessionDto } from "~/dto/auth.dto";

import { toastService } from "~/services";
import authService from "~/services/auth.service";


interface IAuthStore {
  isFirstLoading: boolean;
  isLoading: boolean;
  logged: boolean;
  userInfo: UserSessionDto;
  login: (body: LoginReq) => Promise<void>;
  authenticate: () => Promise<void>;
  logout: () => void;
}


const excludePaths = [
  "/login",
  "/register",
  "/demo"
]

const AuthStoreContext = createContext<IAuthStore | undefined>(undefined);

const AuthStoreProvider = ({ children }: { children: React.ReactNode; }) => {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logged, setLogged] = useState(localStorage.getItem(KeyLocalStore.accessToken) ? true : false);
  const [userInfo, setUserInfo] = useState<UserSessionDto>(undefined);

  useEffect(() => {
    const check = localStorage.getItem(KeyLocalStore.accessToken) ? true : false;
    setLogged(check)
  }, [])

  const login = useCallback(async (body: LoginReq, pathNavigate: string = "/") => {
    setIsLoading(true);
    try {
      const user = await authService.login(body);
      if (user.accessToken) {
        setLogged(true);
        localStorage.setItem(KeyLocalStore.accessToken, `${user.accessToken}`);
        setUserInfo(user);
        navigate(pathNavigate);
      }
    } catch (error) {
      toastService.handleError(error);
      console.log({ error });
    }
    setIsLoading(false);
  }, [navigate])



  const authenticate = useCallback(async () => {
    setIsFirstLoading(true);
    try {
      // const user = await authService.authenticate();
      const check = localStorage.getItem(KeyLocalStore.accessToken) ? true : false;
      if (check) {
        setLogged(true);
      }
    } catch (error) {
      //  toastService.handleError(error);
      setLogged(false);
    }
    setIsFirstLoading(false);
  }, [])

  const logout = useCallback(() => {
    localStorage.removeItem(KeyLocalStore.accessToken);
    setLogged(false);
  }, [])

  useEffect(() => {

    if (!logged && !excludePaths.includes(pathname)) {
      navigate("/login")
    }

  }, [logged, navigate, pathname])




  const values = useMemo(
    () => ({
      isFirstLoading,
      isLoading,
      logged,
      userInfo,
      login,
      authenticate,
      logout,
    }),
    [authenticate, isFirstLoading, isLoading, logged, login, logout, userInfo]
  )

  return <AuthStoreContext.Provider value={values}>{children}</AuthStoreContext.Provider>

}

const useAuthStore = () => {
  const context = useContext(AuthStoreContext);
  if (context === undefined) {
    throw new Error('useWallet hook must be used with a AuthStoreContext component')
  }
  return context;
}

export {
  AuthStoreProvider,
  useAuthStore,
}



