import { EditOutlined } from "@ant-design/icons";
import { Spin, Row, Button, Tooltip, Col, Modal, Form, Input, InputNumber } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PageResponse } from "~/@ui/GridControl/models";
import TableControl from "~/@ui/TableControl";
import helper from "~/common/helper/helper";
import BaseView from "~/components/BaseView";
import { IPointMintRex } from "~/dto/point.mintrex.dto";
import { toastService } from "~/services";
import mintrexPointService from "~/services/mintrex.point.service";

export const MintString = () => {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [refesh, setRefesh] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = useForm();
  const [item, setItem] = useState(null);

  const columns: ColumnsType<IPointMintRex> = [
    {
      title: 'Bonus Point',
      dataIndex: 'bonusPoint',
      key: 'bonusPoint',
      ellipsis: true,
    },
    {
      title: 'Qty Mint',
      dataIndex: 'qtyMint',
      key: 'qtyMint',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{helper.formatPrice(value.toString())}</span>
      }
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      ellipsis: true,
    },
    {
      key: "action",
      title: "Action",
      ellipsis: true,
      fixed: 'right',
      render: (value: any, record: IPointMintRex, index: number) => {
        return (
          <span>

            <Tooltip title="Edit Mint String">
              <Button type="primary" shape="circle" icon={<EditOutlined />}
                title={"Edit Mint String"}
                onClick={() => {
                  setIsOpen(true);
                  setItem(record)

                  form.setFieldsValue({ ...record })
                }}
              />
            </Tooltip>

          </span>
        )
      }
    }
  ];

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    const rs = await mintrexPointService.list({
      pageIndex, pageSize,
    })

    return {
      data: rs.data,
      total: rs.total
    } as PageResponse;
  }, [refesh])


  const handleSave = async () => {

    setIsLoading(true);
    try {
      const values = form.getFieldsValue();
      console.log({ values, item });

      await mintrexPointService.edit({ id: item.id, ...values });
      setIsOpen(false);
      toastService.success("Edit Success");

    } catch (error) {
      toastService.error(error)
    }
    setIsLoading(false);
  };

  return (
    <BaseView>

      <Spin
        spinning={isLoading}
      >

        <Row
          gutter={[24, 2]}
          style={{ paddingBottom: "20px", alignItems: "end" }}
        >
          <Col span={8}>
            <Button
              style={{ width: "180px" }}
              type="primary"
              onClick={() => navigate("/mintrex/create-mint-string")}
            >
              Create Mint String
            </Button>
          </Col>

        </Row>

        <TableControl
          columns={columns}
          defaultPageSize={10}
          loadData={loadData}
        />

      </Spin>

      <Modal
        title="Edit Mint String"
        open={isOpen}
        onOk={handleSave}
        onCancel={() => setIsOpen(false)}
      >
        <Row gutter={[24, 2]}>

          <Form
            form={form}
            layout="vertical"
          // onFinish={handleSave}
          >

            <Row gutter={[24, 2]}>
              <Col span={24}>
                <Form.Item
                  label="Qty Mint"
                  name="qtyMint"
                  rules={[{
                    required: true,
                    message: 'The input is not valid!'
                  }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Bonus Point"
                  name="bonusPoint"
                  rules={[{
                    required: true,
                    message: 'The input is not valid!'
                  }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="Desciption"
                  name="desc"
                  rules={[{
                    required: true,
                    message: 'The input is not valid!'
                  }]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>

            </Row>
          </Form>

        </Row>
      </Modal>

    </BaseView>
  )
}
