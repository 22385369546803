

import { Tabs } from 'antd';
import { FC, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useLayoutConfig } from '~/stores/layoutConfig';
import TabsAction from './TabsAction';



function pipePath(path: string) {
  const regexConfig = /(^\/+|\/+$)/mg;
  return path.replace(regexConfig, "")
}

type IMainTabsProps = {
}

const MainTabs: FC<IMainTabsProps> = (props: IMainTabsProps) => {
  const {
    selectedKey,
    tabs, setActiveTab,
    flatLayoutResource,
    addTab,
    removeTab,
  } = useLayoutConfig();

  const { pathname, search } = useLocation();

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  const onEdit = (targetKey: string, action: "add" | "remove") => {
    if (action === "remove") {
      removeTab(targetKey);
    }
  }
  // useEffect(() => {
  //   document.title = translate(selectedTitle)
  // }, [selectedTitle])

  useEffect(() => {
    console.log(`=====useEffect=====`);
    console.log(`-------------------`);
    console.log({ pathname, search });
    console.log(`-------------------`);
    const tab = flatLayoutResource.find(v => pipePath(v.rootPath) === pipePath(pathname));

    if (tab) {
      addTab(tab, search);
    }
  }, [addTab, flatLayoutResource, pathname, search])
  return (
    <div id="pageTabs" style={{ padding: '6px 4px' }}>
      <Tabs
        tabBarStyle={{ margin: 0 }}
        onChange={onChange}
        activeKey={selectedKey}
        type="editable-card"
        hideAdd
        onEdit={onEdit}
        tabBarExtraContent={<TabsAction />}
        items={tabs.map(tab => {
          return {
            key: tab.key,
            closable: tab.closable,
            label: tab.title
          };
        })}
      />
    </div>
  );
}
export default MainTabs;
