
import { ApartmentOutlined, BellOutlined, CrownOutlined, DashboardOutlined, DollarOutlined, EditOutlined, KeyOutlined, PlusOutlined, SettingOutlined, TransactionOutlined, TrophyOutlined, UnorderedListOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router-dom';
import { v4 } from 'uuid';
import { IRouter } from '~/routers';
import CollectionsView from './NftFeed/CollectionsView';
import DetailCollections from './NftFeed/CollectionsView/DetailCollections';
import ToolCampaignX from './ToolCampaignX';
import { CloneCampaignX } from './ToolCampaignX/CloneCampaignX';
import { JobScanView } from './JobScanView';
import { DetailJobScan } from './JobScanView/DetailJobScan';
import { CreateJobScan } from './JobScanView/CreateJobScan';
import ScanUserX from './ScanUserX';
import CollectionsViewMintRex from './MintRex/CollectionsView';
import DetailCollectionsMintRex from './MintRex/CollectionsView/DetailCollections';
import CrawView from './MintRex/CrawView';
import { MintString } from './MintRex/MintString';
import { CreateMintString } from './MintRex/MintString/CreateMintString';
import FeedCrawView from './NftFeed/CrawView';
import PopupTwitterView from './NftFeed/PopupTwitterView';
import PresaleView from './NftFeed/PresaleView';
import { TreeView } from './PlantHarvest/TreeView';
import { NewOnTonView } from './PlantHarvest/NewOnTonView';
import { DashboardView } from './DashboardView';

export const mainRouter: IRouter[] = [

  {
    path: "/",
    key: v4(),
    element: <DashboardView />,
    title: "Dashboard",
    isMenu: true,
    icon: <DashboardOutlined />,
  },

  {
    path: "/mint-nftfeed",
    key: v4(),
    element: <Outlet />,
    title: "Mint NFTFeed",
    isMenu: true,
    icon: <SettingOutlined />,
    children: [
      {
        path: "",
        key: v4(),
        element: <CollectionsView />,
        title: "NFTFeed Collections",
        isMenu: true,
        icon: <UnorderedListOutlined />,
      },
      {
        path: "edit-collections",
        key: v4(),
        element: <DetailCollections />,
        title: "Edit NFTFeed Collections",
        isMenu: false,
        icon: <PlusOutlined />,
      },
      {
        path: "feed-crawl-nft",
        key: v4(),
        element: <FeedCrawView />,
        title: "Feed Crawl NFT",
        isMenu: true,
        icon: <PlusOutlined />,
      },
      {
        path: "popup-twitter",
        key: v4(),
        element: <PopupTwitterView />,
        title: "Popup Twitter",
        isMenu: true,
        icon: <PlusOutlined />,
      },
      {
        path: "Presale",
        key: v4(),
        element: <PresaleView />,
        title: "Presale",
        isMenu: true,
        icon: <PlusOutlined />,
      },
    ]
  },

  {
    path: "/mintrex",
    key: v4(),
    element: <Outlet />,
    title: "MintRex",
    isMenu: true,
    icon: <SettingOutlined />,
    children: [
      {
        path: "",
        key: v4(),
        element: <CollectionsViewMintRex />,
        title: "MintRex Collections",
        isMenu: true,
        icon: <UnorderedListOutlined />,
      },
      {
        path: "edit-collections",
        key: v4(),
        element: <DetailCollectionsMintRex />,
        title: "Edit MintRex Collections",
        isMenu: false,
        icon: <PlusOutlined />,
      },
      {
        path: "crawl-nft",
        key: v4(),
        element: <CrawView />,
        title: "Crawl NFT",
        isMenu: true,
        icon: <PlusOutlined />,
      },
      {
        path: "mint-string",
        key: v4(),
        element: <MintString />,
        title: "Mint String",
        isMenu: true,
        icon: <UnorderedListOutlined />,
      },
      {
        path: "create-mint-string",
        key: v4(),
        element: <CreateMintString />,
        title: "Create Mint string",
        isMenu: false,
        icon: <UnorderedListOutlined />,
      },
    ]
  },

  {
    path: "/plant-harvest",
    key: v4(),
    element: <Outlet />,
    title: "Plant Harvest",
    isMenu: true,
    icon: <SettingOutlined />,
    children: [
      {
        path: "",
        key: v4(),
        element: <TreeView />,
        title: "Manager Trees",
        isMenu: true,
        icon: <UnorderedListOutlined />,
      },
      {
        path: "news-on-ton",
        key: v4(),
        element: <NewOnTonView />,
        title: "News on TON",
        isMenu: true,
        icon: <UnorderedListOutlined />,
      },
    ]
  },

  // {
  //   path: "/tool-x",
  //   key: v4(),
  //   element: <Outlet />,
  //   title: "Tool X",
  //   isMenu: true,
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       path: "",
  //       key: v4(),
  //       element: <ToolCampaignX />,
  //       title: "X Campaign",
  //       isMenu: true,
  //       icon: <UnorderedListOutlined />,
  //     },
  //     {
  //       path: "clone-tool-x",
  //       key: v4(),
  //       element: <CloneCampaignX />,
  //       title: "Edit X Campaign",
  //       isMenu: false,
  //       icon: <PlusOutlined />,
  //     },
  //   ]
  // },

  // {
  //   path: "/job-scan",
  //   key: v4(),
  //   element: <Outlet />,
  //   title: "Job Scan",
  //   isMenu: true,
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       path: "",
  //       key: v4(),
  //       element: <JobScanView />,
  //       title: "Job Scan",
  //       isMenu: true,
  //       icon: <UnorderedListOutlined />,
  //     },
  //     {
  //       path: "create-job-scan",
  //       key: v4(),
  //       element: <CreateJobScan />,
  //       title: "Create Job Scan",
  //       isMenu: false,
  //       icon: <PlusOutlined />,
  //     },
  //     {
  //       path: "detail-job-scan",
  //       key: v4(),
  //       element: <DetailJobScan />,
  //       title: "Detail Job Scan",
  //       isMenu: false,
  //       icon: <PlusOutlined />,
  //     },
  //   ]
  // },

  // {
  //   path: "/scan-user-x",
  //   key: v4(),
  //   element: <Outlet />,
  //   title: "Scan User X",
  //   isMenu: true,
  //   icon: <SettingOutlined />,
  //   children: [
  //     {
  //       path: "",
  //       key: v4(),
  //       element: <ScanUserX />,
  //       title: "Scan User X",
  //       isMenu: true,
  //       icon: <UnorderedListOutlined />,
  //     },
  //   ]
  // },
]
