


import { FC } from 'react';
import App from './App';
import { ThemeStoreProvider } from './stores/themeStore';
import { AuthStoreProvider } from './stores/authStore';
import { BrowserRouter } from 'react-router-dom';
import { LayoutConfigProvider } from './stores/layoutConfig';




interface IProps {

}

const composeProviders = (...providers: any[]) => (Child) => (props) => (
  providers.reduce((acc, Provider) => (
    <Provider>
      {acc}
    </Provider>
  ), <Child {...props} />)
)
const WrappedApp = composeProviders(
  ThemeStoreProvider,
  AuthStoreProvider,
  LayoutConfigProvider,
)(App)

const AppRoot: FC<IProps> = () => {

  return (
    <BrowserRouter >
      <WrappedApp />
    </BrowserRouter>
  )
}



export default AppRoot;
// export default process.env.NODE_ENV === "development" ? hot(AppRoot) : AppRoot
