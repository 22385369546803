
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Spin, Input, Typography, Image, } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import TableControl from '~/@ui/TableControl';
import { toastService } from '~/services';
import { DeleteOutlined, EditOutlined, PlusOutlined, ScanOutlined, SearchOutlined, SendOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PageResponse } from '~/@ui/GridControl/models';
import helper from '~/common/helper/helper';
import { ITree } from '~/dto/ITree';

const { Title } = Typography;

type IProps = {
}

export const TreeView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refesh, setRefesh] = useState(true);

  const [name, setName] = useState("");
  const [itemCol, setItemCol] = useState<ITree>(null);


  const columns: ColumnsType<ITree> = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Seed Price',
      dataIndex: 'seedPrice',
      key: 'seedPrice',
      ellipsis: true,
    },
    {
      title: 'Harvest Point',
      dataIndex: 'harvestPoint',
      key: 'harvestPoint',
      ellipsis: true,
    },
    {
      title: 'Harvest Time',
      dataIndex: 'harvestTime',
      key: 'harvestTime',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <Image src={value || null} style={{ width: 40, height: 40 }} />
      }
    },
    {
      title: 'Timeline',
      dataIndex: 'timeline',
      key: 'timeline',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <Image src={value || null} style={{ width: 40, height: 40 }} />
      }
    },
    {
      key: "action",
      title: "Action",
      ellipsis: true,
      fixed: 'right',
      render: (value: any, record: ITree, index: number) => {
        return (
          <span>

            <Tooltip title="Edit ">
              <Button type="primary" shape="circle" icon={<EditOutlined />}
                onClick={() => {
                  openModal(record);
                }}
              />
            </Tooltip>

          </span>
        )
      }
    }

  ];

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {

    return {
      data: [1],
      total: 0
    } as PageResponse;
  }, [refesh])

  const handleCancelModal = () => {
    setOpenDelModal(false);
  };

  const openModal = useCallback((item: ITree) => {
    setOpenDelModal(true);
    setItemCol(item);
  }, []);

  const handleOkModal = async () => {
    setConfirmLoading(true);
    try {

      toastService.success("Update POPULAR success!");
      setConfirmLoading(false);
      setRefesh(!refesh)
    } catch (error) {
      toastService.error(error);
      setConfirmLoading(false);
    }
  }

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        <TableControl
          columns={columns}
          defaultPageSize={500}
          loadData={loadData}
        />


      </Spin>

      <Modal
        width={"60%"}
        title={`Edit project : [${name}]`}
        okType="danger"
        okText="SET"
        open={openDelModal}
        onOk={handleOkModal}
        confirmLoading={confirmLoading}
        onCancel={handleCancelModal}
      >

        <Divider />
        {itemCol &&
          <Row
            gutter={[24, 2]}
            style={{ paddingBottom: "20px", alignItems: "end" }}
          >

            <Col span={12}>
              <Title level={5}>{"Name"}</Title>
              <Input
                value={itemCol.name}
                onChange={(e) => {
                  setItemCol({
                    ...itemCol,
                    name: e.target.value,
                  })
                }}
              />
            </Col>

            <Col span={12}>
              <Title level={5}>{"Image"}</Title>
              <Input
                value={itemCol.image}
                onChange={(e) => {
                  setItemCol({
                    ...itemCol,
                    image: e.target.value,
                  })
                }}
              />
            </Col>

            <Col span={12}>
              <Title level={5}>{"Timeline"}</Title>
              <Input
                value={itemCol.imagePath}
                onChange={(e) => {
                  setItemCol({
                    ...itemCol,
                    imagePath: e.target.value,
                  })
                }}
              />
            </Col>

          </Row>
        }

      </Modal>

    </BaseView>
  )
}

