import type { RcFile, } from 'antd/es/upload';
class StringFormatHelper {
  formatNumber(number: string) {
    let numberInt = parseFloat(number),
      lang = localStorage.getItem('lang') || 'en';

    if (Number.isNaN(numberInt)) {
      return 0;
    }

    if (lang === 'vi') {
      lang = 'vi';
    }

    return numberInt.toLocaleString(lang);
  }

  numberToString(val: number) {
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  stringToNumber(val: string) {
    //@ts-ignore
    return `${val || "0"}`.replace(/\$\s?|(,*)/g, '') - 0;
  }

  getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  pipeLongTextUi = (value: string = "", leftCharAmount = 4, rightCharAmount = 4) => {
    if (value.length <= leftCharAmount + rightCharAmount + 3) {
      return value;
    }
    return `${value?.substring(0, leftCharAmount) ?? ''}...
  ${value?.substring(value.length - rightCharAmount) ?? ''}`
  }


  formatPrice = (price: string) => {
    if (!price) {
      return '0'
    }

    if (!`${price}`.includes(',')) {
      return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const seperatorList = `${price}`.split(',')
    const intPart = seperatorList[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const decimalPart = seperatorList[1] || ''
    return `${intPart},${decimalPart}`
  }

}
const helper = new StringFormatHelper();
export default helper;
