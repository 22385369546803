import { PageRequest, PageResponse } from "~/@ui/GridControl/models";
import { ILauchPool } from "~/dto/ILauchPool";
import { rootApiService } from "./@common";


const ENDPOINT = {
  list: "/api/public/launch-pool/list-launch-pool".trim(),

  edit: "/api/manager/launch-pools/update".trim(),

}

export class PresaleService {

  async list(body: PageRequest & { managerAddress?: string }) {
    return rootApiService.get<PageResponse<ILauchPool>>(ENDPOINT.list, body);
  }

  async edit(body: {
    chainId: number,
    presaleAddress: string,
    startTime?: Date,
    endTime?: Date,
    isTrend?: boolean
  }) {
    return rootApiService.post<PageResponse<ILauchPool>>(ENDPOINT.edit, body);
  }

}

export default new PresaleService();
