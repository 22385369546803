
import { PageRequest, } from "~/@ui/GridControl/models";
import { CollectionsDTO, } from "~/dto";
import { mintRexApiService } from "./@common";
import { EStatus } from "~/common/enums";

const Enpoint = {
  list: "/api/admin/nft-collections/list".trim(),
  crawl: "/api/admin/tools/crawl".trim(),
  updateCollection: "/api/admin/nft-collections/update".trim(),
  updateMintConfig: "/api/admin/tools/update-mint-config-by-txs".trim(),
}

export class MintRexCollectionsService {

  async list(params: PageRequest & {
    name?: string,
    address?: string,
  }) {
    return mintRexApiService.get<{ data: CollectionsDTO[], total: number }>(Enpoint.list, params);
  }

  async crawlNft(params: {
    chainId: number,
    address: string,
  }) {
    return mintRexApiService.post<{ address: string }>(Enpoint.crawl, { ...params, crawlType: "NFT_SCAN" });
  }

  async updateCollection(params: {
    chainId: number;
    address: string;
    systemTag?: number,
    status?: EStatus
    isOffer?: number,
    descriptionCustom?: string;
    twitterId?: string;
  }) {
    return mintRexApiService.post<CollectionsDTO>(Enpoint.updateCollection, params);
  }

  async updateMintConfig(params: {
    chainId: number,
    address: string,
    txHashs: string[],
  }) {
    return mintRexApiService.post(Enpoint.updateMintConfig, params);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MintRexCollectionsService();
