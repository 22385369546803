
import { useState, FC, useCallback, useRef } from 'react';
import { Button, Divider, theme, Col, Row, Modal, Tooltip, Tag, Image, Avatar, Spin, Input, Switch } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import TableControl from '~/@ui/TableControl';
import { toastService } from '~/services';
import { DeleteOutlined, EditOutlined, PlusOutlined, SendOutlined, } from '@ant-design/icons';
import moment from 'moment';
import BaseView from '~/components/BaseView';
import { createSearchParams, useNavigate } from 'react-router-dom';
import presaleService from '~/services/presale.service';
import { PageResponse } from '~/@ui/GridControl/models';
import { ESystemTag } from '~/common/enums';
import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import helper from '~/common/helper/helper';
import Title from 'antd/es/typography/Title';
import { ILauchPool } from '~/dto/ILauchPool';
import { EStatusLaunch } from '~/common/enums/EStatusLaunch';


const checkURLType = (url: string) => {
  if (!url) {
    return "Other";
  }
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov'];
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const extension = url.split('.').pop().toLowerCase();
  if (videoExtensions.includes(extension)) {
    return 'Video';
  } else if (imageExtensions.includes(extension)) {
    return 'Image';
  } else {
    return 'Other';
  }
}

type IProps = {
}

const PresaleView: FC<IProps> = (props: IProps) => {

  const { token: { colorPrimary } } = theme.useToken();
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refesh, setRefesh] = useState(false);

  const [name, setName] = useState("");
  const [itemCol, setItemCol] = useState<ILauchPool>(null);
  const [total, setTotal] = useState(0);
  const [keySearch, setKeySearch] = useState({
    name: "",
    address: "",
  });


  const columns: ColumnsType<ILauchPool> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      render: (value: any, record: ILauchPool, index: number) => {
        return <Tag>
          <span>{EStatusLaunch[value]}</span>
        </Tag>
      }
    },
    {
      title: "Trend",
      dataIndex: "isTrend",
      key: "isTrend",
      ellipsis: true,
      render: (value: any, record: ILauchPool, index: number) => {
        return record.isTrend && <Tag color={"cyan"}>
          <span>{"Trend"}</span>
        </Tag>
      }
    },
    {
      title: 'Chain',
      dataIndex: 'chainId',
      key: 'chainId',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <div>
          <Avatar src={EVM_CHAIN_LIST[value].logo} size={"small"} />
          {` `} <span>{EVM_CHAIN_LIST[value].chainName}</span>
        </div>
      }
    },
    {
      title: 'Name',
      dataIndex: 'tokenName',
      key: 'tokenName',
      ellipsis: true,
    },
    {
      title: 'Total Raise',
      dataIndex: 'totalRaised',
      key: 'totalRaised',
      ellipsis: true,
      render: (value: string, record: any, index: number) => {
        return <span>{helper.formatPrice(value.toString())} {record.currency}</span>
      }
    },
    {
      title: 'Softcap',
      dataIndex: 'softCap',
      key: 'softCap',
      ellipsis: true,
      render: (value: string, record: ILauchPool, index: number) => {
        return <span>{helper.formatPrice(value.toString())} {record.currency}</span>
      }
    },
    {
      title: "Project",
      dataIndex: "address",
      key: "address",
      render: (value: any, record: ILauchPool, index: number) => {
        return <Tag
          color={"default"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(`https://mint.nftfeed.guru/feedipad/${record.chainId}/${record.presaleAddress}`)
          }}
        >
          <span>Website</span>
        </Tag>
      }
    },
    {
      key: "action",
      title: "Action",
      ellipsis: true,
      fixed: 'right',
      render: (value: any, record: ILauchPool, index: number) => {
        return (
          <span>

            <Tooltip title="Action">
              <Button
                size='small'
                type="primary"
                icon={<EditOutlined />}
                title={"deactive"}
                onClick={openModal.bind(null, record)}
              >
                Action
              </Button>
            </Tooltip>

          </span>
        )
      }
    }

  ];

  const onChange = useCallback((key: string, val: string) => {
    setKeySearch({
      ...keySearch,
      [key]: val
    })
  }, [keySearch])

  const loadData = useCallback(async ({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }) => {
    const rs = await presaleService.list({
      pageIndex, pageSize,
      ...(keySearch.address && { address: keySearch.address.trim() }),
      ...(keySearch.name && { name: keySearch.name.trim() }),
    })
    setRefesh(false);

    setTotal(rs.total);
    return {
      data: rs.data,
      total: rs.total
    } as PageResponse;
  }, [refesh])

  const handleCancelModal = () => {
    setOpenDelModal(false);
  };

  const openModal = useCallback((item: ILauchPool) => {
    setOpenDelModal(true);
    setName(item.tokenName);
    setItemCol(item);
  }, []);

  const handleOkModal = async () => {
    setConfirmLoading(true);
    try {

      console.log({ itemCol });

      const rs = await presaleService.edit({
        chainId: itemCol.chainId,
        presaleAddress: itemCol.presaleAddress,
        isTrend: itemCol.isTrend
      });

      setOpenDelModal(false);
      setName("");
      setItemCol(null);
      toastService.success("Update success!");
      setConfirmLoading(false);
      setRefesh(!refesh)
    } catch (error) {
      toastService.error(error);
      setConfirmLoading(false);
    }
  }

  return (
    <BaseView>

      <Spin
        spinning={confirmLoading}
      >

        {/* <Row
          gutter={[24, 2]}
          style={{
            paddingBottom: 20
          }}
        >

          <Col span={8}>
            <Title level={5}>Name</Title>
            <Input
              placeholder='Name Collection'
              value={keySearch["name"]}
              onChange={(e) => onChange("name", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Address</Title>
            <Input
              placeholder='Address Collection'
              value={keySearch["address"]}
              onChange={(e) => onChange("address", e.target.value || "")}
            />
          </Col>

          <Col span={8}>
            <Title level={5}>Search</Title>
            <Row>
              <Button onClick={() => { setRefesh(!refesh); }}>
                Search
              </Button>
              <Button onClick={() => {
                setKeySearch({
                  name: "",
                  address: "",
                })
                setRefesh(!refesh);
              }}>
                Reset
              </Button>
            </Row>
          </Col>

        </Row> */}

        {/* <Title level={5}>Total collection: {total}</Title> */}

        <TableControl
          columns={columns}
          defaultPageSize={50}
          loadData={loadData}
        />

        <Modal
          title={`Edit project : [${name}]`}
          okType="danger"
          okText="SET"
          open={openDelModal}
          onOk={handleOkModal}
          confirmLoading={confirmLoading}
          onCancel={handleCancelModal}
        >

          <Divider />
          {itemCol &&
            <Row
              gutter={[24, 2]}
              style={{ paddingBottom: "20px", alignItems: "end" }}
            >

              <Col span={12}>
                <Title level={5}>{"Trend"}</Title>
                <Switch
                  checked={itemCol.isTrend}
                  onChange={(e) => {
                    setItemCol({
                      ...itemCol,
                      isTrend: e,
                    })
                  }}
                />
              </Col>

            </Row>
          }

        </Modal>

      </Spin>

    </BaseView>
  )
}

export default PresaleView;
