import { IEnvConfig } from '..';
import { CHAIN_CODE } from "../chain-code";
import { EVM_CHAIN_LIST } from "../chain-list";

const config: IEnvConfig = {
  name: "DEVELOPMENT",
  IMAGE_URL: "",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://qa.api.nftfeed.guru".trim(),
    },
    MINTREX: {
      baseUrl: "http://localhost:5002".trim(),
    },
    PLANT_HARTVEST: {
      baseUrl: "".trim(),
    }
  },
  CHAINS: [
    EVM_CHAIN_LIST[CHAIN_CODE.ARBITRUM_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.BASE_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.OP_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.SCROLL_MAINNET],
    EVM_CHAIN_LIST[CHAIN_CODE.OPBNB],
  ]
}

export default config;
